




/* --------------------------------------------------- body */

h1,
h2,
h3,
h4,
h5 {
    font-weight: bold;
}

a {
    text-decoration: none;
}

h1 {
    font-size: 28px;
}

h2 {
    font-size: 24px;
    line-height: 30px;
    text-align: left;
}

h2>i {
    margin-right: 0.5rem;
}

h3 {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.1em;
    text-align: left;
    font-weight: bold;
}

h3.modal-category {
    letter-spacing: normal;
    margin-bottom: 0;
}



p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

input {
    border: 1px solid #cccccc;
    padding: 0.4em 0.8em;
    border-radius: 8px;
}

select.form-select {
    padding: 0.2em 1.4em;
    border-radius: 4px;
}

input:focus {
    outline: 1px solid #4e4e4e;
}

.list-checkbox {
    min-height: 1.65rem;
    min-height: 1.65rem;
}

small {
    letter-spacing: 0.1em;
}

h6 {
    letter-spacing: 0.1em;
}

p.p-main-text {
    padding-right: 10rem;
    font-size: 1rem;
    font-family: 'Inter';
    font-weight: 400;
}


.text-link,
.text-link::after,
.text-link:focus,
.text-link:hover {
    color: black;
}

#side-header {
    padding-top: 3rem !important;
    padding-left: 1.5rem !important;
}

.side-header-title {
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.1em;
    column-gap: 0.75rem;
    align-items: center;
}

.side-header-title>i {
    font-size: 1.375rem;
}


#dropdownUser {
    font-size: 1rem !important;
    font-weight: 700;
}

.dropdown fixed-right {
    padding-right: 1.5rem;
}

.side-bar-column {
    padding-top: 5%;
    flex-direction: column;
}

.pointer-on-hover {
    cursor: pointer;
}

.warning-info-box {
    background-color: rgba(255, 189, 136, 27%);
    padding: 1.5em 1.5em 1em;
}

/* -------------------------------------------------- forms */


/* label {
visibility: hidden !important;
height: 0;
} */

label[for="id_tag_monitor"],
label[for="id_tag_monitor_server"],
label[for="id_datalayer_guard"],
label[for="id_sitespeed_monitor"],
label[for="id_is_admin"],
label[for="id_is_agency"],
label[for="id_method-method_0"],
label[for="id_method-method_1"],
label[for="id_email_updates"],
label[for="id_slack_updates"],
label[for="id_teams_updates"] {
    visibility: visible !important;
}

#div_id_datalayer_guard,
#div_id_sitespeed_monitor,
#div_id_tag_monitor,
#div_id_tag_monitor_server,
#div_id_is_agency {
    text-align: start !important;
}

#div_id_email_updates,
#div_id_slack_updates,
#div_id_teams_updates {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 3rem;
}

#div_id_emails,
#div_id_slack_channel,
#div_id_teams_channel {
    margin-top: 0;
    padding-top: 0;
}

#div_id_datalayer_guard {
    margin-top: 15px;
}



#form-filters .form-control,
#form-filters .form-select {

    border-color: #fff;
    border-radius: 0 !important;
    -webkit-box-shadow: 0px 6px 10px 0px rgb(74 74 74 / 30%);
    -moz-box-shadow: 0px 6px 10px 0px rgb(74 74 74 / 30%);
    box-shadow: 0px 6px 10px 0px rgb(74 74 74 / 30%);

}


#form-filters select.form-select option:hover,
#form-filters select.form-select option:focus,
#form-filters select.form-select option:active {
    background: #dddddd !important;
    background-color: #dddddd !important;
}



/* ---------------------------------------------------- bootstrap toasts */
.message-container {
    position: fixed;
    right: 5px;
    z-index: 99999999999;
}

.custom-toast {
    overflow: visible;
    opacity: 0.8;
    width: 100%;
}

.toast-capper {
    height: 2px;
}

.flex-container {
    display: flex;
    gap: 0.5em;
    align-items: center;
}

.navbar-column {
    padding: 2em 0 0 0;
    max-width: 75%;
    row-gap: 1rem;
}

.header-user-area-text {
    width: 100%;
    font-size: 1.2rem;
    text-align: end;
}

.header-maintenance-warning {
    margin: 0 0 3em 0;
}

.header-desc {
    line-height: 3em;
}

/* label[for="id_email"], label[for="id_password1"], label[for="id_password2"], label[for="id_auth-username"], 
label[for="id_auth-password"], label[for="id_phone_number"], label[for="id_company_name"], label[for="id_full_name"]{
    visibility: hidden !important;
    height: 0;    
} */

label[for="id_understand"] {
    visibility: visible !important;
}

/* ---------------------------------------------------- dashboard */
.card {
    border-radius: 0.5rem;
}

.btn.btn-primary:disabled {
    background-color: #2B2BB5;
    opacity: 1;
    pointer-events: none;
}

.btn.btn-secondary:disabled {
    background-color: #00E1C7;
    opacity: 1;
    border-color: #00E1C7;
    pointer-events: none;
}


.table-header>th {
    background-image: linear-gradient(#2B2BB5, transparent) !important;
}

.desc-italic-sm {
    font-size: 9pt;
    font-style: italic;
}



#cscid,
#sscid {
    text-align: center;
    border: none;
    min-width: 50%;
    width: auto;
    height: 30px;
}

#startDate,
#endDate {
    text-align: center;
    border: none;
    height: 30px;
}

@media screen and (min-width: 768px) {
    #dropdownUser {
        font-size: 1.25rem !important;
    }

    .side-bar-div {
        min-width: 18%;
        max-width: 18%;
    }

    .side-bar-div>div {
        padding-left: 2em;
    }

    .main-content-div {
        max-width: 82%;
        min-width: 82%;
    }
}

@media screen and (min-width: 1024px) {
    #dropdownUser {
        font-size: 1.25rem !important;
    }

    .side-bar-div {
        min-width: 18%;
        max-width: 18%;
    }

    .side-bar-div>div {
        padding-left: 2em;
    }

    .main-content-div {
        max-width: 82%;
        min-width: 82%;
    }
}

@media screen and (min-width: 1280px) {
    #dropdownUser {
        font-size: 1.25rem !important;
    }

    .side-bar-div {
        min-width: 18%;
        max-width: 18%;
    }

    .side-bar-div>div {
        padding-left: 2em;
    }

    .main-content-div {
        max-width: 82%;
        min-width: 82%;
    }
}

@media screen and (min-width: 1440px) {
    #dropdownUser {
        font-size: 1.25rem !important;
    }

    .side-bar-div {
        min-width: 18%;
        max-width: 18%;
    }

    .side-bar-div>div {
        padding-left: 2em;
    }

    .main-content-div {
        max-width: 82%;
        min-width: 82%;
    }
}


@media screen and (min-width: 1920px) {

    .side-bar-div {
        min-width: 14%;
        max-width: 14%;
    }

    .side-bar-div>div {
        padding-left: 2em;
    }

    .main-content-div {
        max-width: 86%;
        min-width: 86%;
    }
}

@media screen and (min-width: 2560px) {
    .side-bar-div {
        min-width: 12%;
        max-width: 12%;
    }

    .side-bar-div>div {
        padding-left: 2em;
    }

    .main-content-div {
        max-width: 88%;
        min-width: 88%;
    }
}

/* ------------------------------- profile */
#profile-card {
    background-color: white;
    padding: 2rem;
    border-radius: 20px;
}

.horizontal-divider {
    /* height: 6px; */
    border: 1px solid #000;
    /* box-shadow: 0 6px 6px -6px #000000 inset; */
}


/* ------------------------------- componenets */

.section {
    margin: 5em 0 0 0;
}

.blue-btn {
    color: #fff;
    background-color: #2b2bb5;
    font-weight: bold;
    border-color: #2b2bb5;
}

.blue-btn-sm {
    color: #fff;
    background-color: #2b2bb5;
    font-weight: bold;
    border-color: #2b2bb5;
    border-radius: 10px;

}


.blue-btn:hover,
.blue-btn:focus,
.blue-btn-sm:hover,
.blue-btn-sm:focus {
    background-color: #2b2bb5e4;
    color: #fff;
    border-color: #2b2bb5e4;
}

.orange-btn-sm {
    color: #fff;
    background-color: #FF661F;
    font-weight: bold;
    border-color: #FF661F;
    border-radius: 10px;
    width: 10rem;
    min-width: 10rem;
    max-width: 10rem;
}

.orange-btn-sm:hover,
.orange-btn-sm:focus {
    background-color: #FF661F;
    color: #fff;
    border-color: #FF661F;
}

.purpule-btn-sm {
    color: #fff;
    background-color: #6535F5;
    font-weight: bold;
    border-color: #6535F5;
    border-radius: 10px;
    width: 10rem;
    min-width: 10rem;
    max-width: 10rem;
}

.purpule-btn-sm:hover,
.purpule-btn-sm:focus {
    background-color: #6535F5;
    color: #fff;
    border-color: #6535F5;
}


.form-submit-button-right {
    display: flex;
    flex-direction: row-reverse;
    margin: 1em 0;
}

.form-submit-button-left {
    display: flex;
    margin: 1em 0;
}

.dl-events-main {
    display: block;
    margin: 2em 0;
}

.dl-events-elem {
    display: grid;
}

.dl-events-row {
    display: grid;
    grid-template: 1fr / 1fr 1fr 1fr 0.1fr;
    margin: 0 0 2em 0;
    grid-column-gap: 4%;
}

.dl-events-headers-row {
    display: grid;
    grid-template: 1fr / 1fr 1fr 1fr 0.1fr;
    grid-column-gap: 4%;
}

.dl-inputs-row {
    display: grid;
    grid-template: 1fr / 0.5fr;
    grid-column-gap: 4%;
    align-items: center;
}

.dl-inputs-row>input {
    cursor: text;
}

.dl-inputs-row-2-col {
    display: grid;
    grid-template: 1fr / 0.5fr 0.5fr;
    grid-column-gap: 1%;
    align-items: center;
}

.inputs-row-2-col-checkbox {
    display: grid;
    grid-template: 1fr / 2.5rem 0.33fr;
}

.inputs-row-3-col {
    display: grid;
    grid-template: 1fr / 0.33fr 0.33fr 0.03fr;
    grid-column-gap: 1%;
    align-items: center;
}

.inputs-row-4-col {
    display: grid;
    grid-template: 1fr / 0.33fr 0.33fr 0.03fr 0.03fr;
    grid-column-gap: 1%;
    align-items: center;
}

.inputs-row-6-col {
    display: grid;
    grid-template: 101% / 3% 26% 26% 32% 5% 2%;
    grid-column-gap: 0.3rem;
    align-items: center;
}


.inputs-row-7-col {
    display: grid;
    grid-template: 100% / 3% 18% 18% 18% 30% 5% 2%;
    grid-column-gap: 0.3rem;
    align-items: center;
}

.inputs-row-7-col label {
    font-size: small;
}



.temp-add-new {
    width: fit-content;
    transition: visibility 0.1s linear, opacity 0.1s linear;
}

.ssm-events-row {
    display: grid;
    grid-template: 1fr / 1fr 1fr 0.1fr;
    margin: 0 0 2em 0;
    grid-column-gap: 4%;
}

.ssm-events-headers-row {
    display: grid;
    grid-template: 1fr / 1fr 1fr 0.1fr;
    grid-column-gap: 4%;
}

.dl-button-container {
    margin: 1em 0;
}

.dl-events-input-short {
    display: block;
    height: 2em;
    width: 100%;
}

.dl-events-input-long {
    max-height: 100%;
    width: 100%;
}

.dl-events-url {
    display: block;
    width: 100%;
}

.dl-events-url-tip {
    font-size: 7pt;
    font-style: italic;
    color: #C8C6C6
}

.input-label-header {
    font-weight: bold;
    font-size: 10pt;
    margin: 1em 0 0.5em 0;
}

.mailing-list-elem {
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    grid-column-gap: 4%;
    margin: 0 0 0.5em 0;
}

.row-elem-main {
    margin: 1.5em 0;
}


.green-btn {
    background-color: #00E1C7;
    color: #fff;
    border-color: #00E1C7;
}

/* ------------------------------------- spacings */


.neg-mg-bt {
    margin-bottom: -1.2rem !important;
}

/* The labels in the forms */
p.neg-mg-bt {
    font-size: 1rem;
}


.n-m-l {
    margin-left: -3rem !important;
}

.col-5.col-5-lg {
    width: 49% !important;
}

.container.dashboards {
    padding-right: 3rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.custom-mt-2 {
    margin-top: 2rem !important;
}


.custom-pt-4 {
    padding-top: 2.3rem;
}


.custom-px-4 {
    padding-left: 2.3rem;
    padding-right: 2.3rem;
}


.custom-p-4 {
    padding: 2.3rem;
}

.custom-pb-4 {
    padding-bottom: 2.3rem;
}



#loading-overlay {
    /* display: none; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #8b8bf5;
    z-index: 9999;
}

.loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 100%;
}


.filters-label-row small {
    font-size: .75em;
}


.inputs-column {
    display: grid;
    grid-template: 1fr / 0.7fr;
}


.accordion-button:not(.collapsed) {
    color: #000;
    background-color: #fff;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion.datalayer-guard {
    width: 50%;
}

.accordion-exp-val {
    padding: 20px;
    background-color: #00e1c717;
    margin: 7px 0px;
    overflow: auto;
    width: 50%;
}


.accordion-actual-val {
    padding: 20px;
    margin-bottom: 0;
    background-color: #ffeeed;
    margin: 7px 0px;
    overflow: auto;
    width: 50%;
}


.list-errors ul:first-child {
    /* height: 15rem;
    max-height:15rem ; */
    background-color: #f2f2f2;
}

.list-errors ul:first-child li {
    border-bottom: 1px solid rgb(212 212 212 / 32%);
    list-style: none;
    font-size: .9em;

}

li::marker {
    padding-right: 1rem !important;
}




.pagination li {
    display: inline-block;
    padding: 3px;
}

.active {
    background: #ececec;
}

table.dataTable tbody tr:hover {
    background-color: #ececec;
    cursor: pointer;
}



pre {
    margin-bottom: 0 !important;
}

pre.dlg_snapshot {
    color: #000;
    padding: 1rem;

}

#datalayer_string {
    /* width: 99%; */
    background-color: #EFEFEF;
    /* max-height:43rem; */
    overflow: auto;
    color: #000;
    /* height: 43rem; */
    display: grid;
    white-space: nowrap;
    border-radius: .3rem;
}






.err-table th {
    width: 20%;
}

.err-table {
    background-color: #e6e6e6;
}


.err-table th,
.err-table td {
    padding: .3rem .5rem;
}

#error-data-table tr {
    border: none;
    border-color: #fff !important;
}

.table-bordered>:not(caption)>* {
    border-color: #fff;
}


.pagination {
    --bs-pagination-active-bg: #0043ff;
    --bs-pagination-focus-color: #0043ff;
    --bs-pagination-hover-color: #0043ff;
    --bs-pagination-active-border-color: #0043ff;
    --bs-pagination-color: #000;
    --bs-pagination-focus-box-shadow: #0043ff;
}

.page-link {
    font-size: .8rem;
}


div.dataTables_wrapper div.dataTables_info {
    color: #0043ff;
}

.align-center {
    margin: auto 0;
}

.align-baseline {
    margin: auto 0 0;
}




.bi.bi-arrow-right {
    margin: 0 0 0 0.5em;
}









/* ////////////////////////////////////////////////////dlg-dashboard */





#actual_value {
    background-color: #FFE1CC;
    border-radius: .5rem;

}





/* ////////////////////////////////////////////////////// */

#template>pre::-webkit-scrollbar-thumb:vertical {
    background: #494949;
    border: .5rem solid #E9FFFE;
    border-radius: 2rem;
}


#template>pre::-webkit-scrollbar-track {
    background: #E9FFFE;
    background-color: #E9FFFE;
}



#template>pre::-webkit-scrollbar-thumb:horizontal {
    background: #494949;
    border: .3rem solid #E9FFFE;
    border-radius: 2rem;
}

#template>pre::-webkit-scrollbar-corner {

    background-color: #E9FFFE;
    background: #E9FFFE;
}


#modal_save,
#modal_save_center {
    background-color: #2B2BB5 !important;
    border-color: #2B2BB5;
    color: #fff;
}

/* //////////////////////////////////////////////////////////////////////////// */
.temp-row {
    display: grid;
    justify-content: start;
    column-gap: .5rem;
    grid-template: 100% / 20% 20% 10%;
    overflow-wrap: break-word;
}

.temp-row-admin-user {
    display: grid;
    justify-content: start;
    column-gap: .5rem;
    grid-template: 100% / 20% 20% 20% 10%;
    overflow-wrap: break-word;
}


.temp-row-trans {
    display: grid;
    justify-content: start;
    column-gap: .5rem;
    grid-template: 100% / 4% 30% 15% 30% 4% 4%;
    overflow-wrap: break-word;
}

.temp-row-inputs {
    display: grid;
    justify-content: start;
    column-gap: .5rem;
    grid-template: 100% / 20% 17% 17% 30% 4% 4%;
    overflow-wrap: break-word;
}

.trans-temp-row-inputs {
    display: grid;
    justify-content: start;
    column-gap: .5rem;
    grid-template: 100% / 30% 15% 30% 4% 4%;
    overflow-wrap: break-word;
}


.row-labels {
    font-weight: bold;
    margin-bottom: .5rem;
}

.temp-row>i,
.temp-row-inputs>i,
.trans-temp-row-inputs>i,
.temp-row-trans>i {
    align-self: flex-end;
    cursor: pointer;
    margin: 0 auto auto;
    font-size: 16pt;
}

.temp-row select.form-select,
.temp-row-trans select.form-select,
.trans-temp-row-inputs select.form-select,
.temp-row-inputs select.form-select {
    padding: 0.2em .6em !important;
}



.disabled-purple-btn.btn.disabled {
    background-color: rgba(101, 53, 245, .5);
    color: #4e4e4e;
    border: none;
    opacity: 1;
}

.none-letter-spacing {
    letter-spacing: 0 !important;
}