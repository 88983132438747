@import './styles/colors';
@import './styles/fonts';
@import './styles/variables';

body {
    margin: 0;
    font-family: 'Inter';
    font-size: $font-size-regular;
}

@media screen and (max-width: 1536px) {
    // Page content area
    .container-content {
        width: 100%;
        padding: 0 3rem 3rem 0;
    }

    // Dashboard description text area
    .dashboard-desc-area {
        width: 100%;
    }
}

@media screen and (min-width: 1537px) {
    // Page content area
    .container-content {
        width: 80%;
        padding-bottom: 3rem;
    }

    // Dashboard description text area
    .dashboard-desc-area {
        width: 80%;
    }
}

// Dashboard light grey charts background
.dash-charts-area {
    height: auto;
    width: 37vw;
    min-height: 22rem;
    min-width: 40rem;
    padding: 1rem 2rem;
    border-radius: .4rem;
}

.consent-monitoring-charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.consent-monitoring-chart {
    flex: 1 1 45%; // Adjust the percentage as needed
    margin: 10px;
    min-width: 300px; // Ensure a minimum width
}

@media (max-width: 768px) {
    .consent-monitoring-chart {
        flex: 1 1 100%; // Full width on smaller screens
    }
}

.page-separator {
    background-color: #e5e5e594;
    height: 1px;
    margin: 0.5em 0;
    width: 50%;
}


.header-letter-spacing {
    // letter-spacing: .04em;
    font-weight: 600;
}

.small-heading {
    color: $cc-purple;
    font-size: 1em;
    font-weight: 600;
}

h3.page-category {
    letter-spacing: normal;
    color: $cc-dark-grey;
    text-transform: uppercase;
}


// Transition effects styles
.m-fadeIn {
    visibility: visible;
    opacity: 1;
}

.m-fadeOut {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

.slide-left {
    right: 0;
    box-shadow: -3px 3px 6px 0px rgba(0, 0, 0, 0.25);
}

.slide-right {
    right: -75%;
    box-shadow: none;
}

.slide-down {
    height: fit-content;
}

.slide-up {
    overflow: hidden;
    height: 0;
}


.bkg-shad {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    transition: visibility $standard-animation-speed ease-in-out, opacity $standard-animation-speed ease-in-out;
    top: 0;
    left: 0;
}


// Charts area
.charts-area {
    display: none;

    &.visible {
        display: block;
        width: 100%;
    }
}

.charts-area-canvas {
    max-height: 50vh;
    min-height: 50vh;
}

@media screen and (min-width: 1537px) {
    .charts-area {
        flex: 0 0 auto;
        width: 68.66666667%;
    }
}


// Dashboard filters
.filters-row {
    margin-bottom: 1rem;
}

.filters-refresh {
    min-width: 2rem;
    cursor: pointer;
    margin: auto 0;
    color: $cc-dark-grey;
}


// Blocks
.elems-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0rem;
}


// Config page panels
.container-rows,
.container-guide {
    row-gap: 0.75rem;
    display: grid;
    margin-bottom: 2rem;
}

.panel-button-container {
    border: 2px solid $cc-lighter-grey;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    column-gap: 1em;
    cursor: pointer;
    transition: color 300ms, background-color 300ms, border-color 300ms;
    color: black;
    text-decoration: none;
}

.panel-button-container:hover {
    color: black;
}

.panel-button-container.button-modal {
    max-width: 38%;
}

.panel-button-icon {
    font-size: 1.75rem;
    height: 3rem;
    width: 3rem;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    color: $cc-orange-t1;
    justify-content: center;
    background-color:  $cc-orange-t2;
    transition: color 300ms, background-color 300ms, border 300ms;
}

.panel-button-header {
    font-weight: bold;
}

.container-rows.modal-panel-button-row,
.container-guide.modal-panel-button-row {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-bottom: 1rem;
}

.container-guide:hover {
    opacity: .8;
}

.file-icon {
    // font-size: 6rem;
    justify-content: start;
    display: inline-flex;
    height: 3rem;
    // width: 3rem;
    align-items: center;
}

.info-icon {
    padding-left: .2em;
    color: $cc-peach;
}

.grey-bg {
    background-color: $cc-lighter-grey;
    border: none !important;
    -webkit-box-shadow: 1px 1px 5px 0 #00000059;
    -moz-box-shadow: 1px 1px 5px 0 #00000059;
    box-shadow: 1px 1px 5px 0 #00000059;
}


// Config page panels - colours
.container-rows> :nth-of-type(1n)>.panel-button-icon {
    color: $cc-orange;
    background-color: $cc-orange-t2;
}


.container-rows> :nth-of-type(1n)> :nth-of-type(1n):hover {
    background-color: $cc-orange-t1;
    border-color: rgba(0, 0, 0, 0);
}

.container-rows> :nth-of-type(1n)> :nth-of-type(1n):hover>.panel-button-icon {
    background-color: rgba(0, 0, 0, 0);
}

.container-rows> :nth-of-type(2n)> :nth-of-type(1n)>.panel-button-icon {
    color: $cc-turquoise;
    background-color: $cc-turquoise-t2;
}

.container-rows> :nth-of-type(2n)> :nth-of-type(1n):hover {
    background-color: $cc-turquoise-t1;
    border-color: rgba(0, 0, 0, 0);
}

.container-rows> :nth-of-type(2n)> :nth-of-type(1n):hover>.panel-button-icon {
    background-color: rgba(0, 0, 0, 0);
}

.container-rows> :nth-of-type(3n)> :nth-of-type(1n)>.panel-button-icon {
    color: $cc-purple;
    background-color: $cc-purple-t2;
}

.container-rows> :nth-of-type(3n)> :nth-of-type(1n):hover {
    background-color: $cc-purple-t1;
    border-color: rgba(0, 0, 0, 0);
}

.container-rows> :nth-of-type(3n)> :nth-of-type(1n):hover>.panel-button-icon {
    background-color: rgba(0, 0, 0, 0);
}


.container-rows> :nth-of-type(4n)> :nth-of-type(1n)>.panel-button-icon {
    color: $cc-pink;
    background-color: $cc-pink-t1;
}

.container-rows> :nth-of-type(4n)> :nth-of-type(1n):hover {
    background-color: $cc-pink-t1;
    border-color: rgba(0, 0, 0, 0);
}

.container-rows> :nth-of-type(4n)> :nth-of-type(1n):hover>.panel-button-icon {
    background-color: rgba(0, 0, 0, 0);
}

.container-rows> :nth-of-type(5n)> :nth-of-type(1n)>.panel-button-icon {
    color: $cc-blue;
    background-color: $cc-blue-t2;
}

.container-rows> :nth-of-type(5n)> :nth-of-type(1n):hover {
    background-color: $cc-blue-t1;
    border-color: rgba(0, 0, 0, 0);
}

.container-rows> :nth-of-type(5n)> :nth-of-type(1n):hover>.panel-button-icon {
    background-color: rgba(0, 0, 0, 0);
}

.container-rows> :nth-of-type(6n)> :nth-of-type(1n)>.panel-button-icon {
    color: $cc-lavender;
    background-color: $cc-lavender-t2;
}

.container-rows> :nth-of-type(6n)> :nth-of-type(1n):hover {
    background-color: $cc-lavender-t1;
    border-color: rgba(0, 0, 0, 0);
}

.container-rows> :nth-of-type(6n)> :nth-of-type(1n):hover>.panel-button-icon {
    background-color: rgba(0, 0, 0, 0);
}

.container-rows> :nth-of-type(6n)> :nth-of-type(1n)>.panel-button-icon {
    color: $cc-blue;
    background-color: $cc-blue-t2;
}

.container-rows> :nth-of-type(6n)> :nth-of-type(1n):hover {
    background-color: $cc-blue-t1;
    border-color: rgba(0, 0, 0, 0);
}

.container-rows> :nth-of-type(6n)> :nth-of-type(1n):hover>.panel-button-icon {
    background-color: rgba(0, 0, 0, 0);
}

.container-rows>div>.disabled {
    pointer-events: none;
    background-color: $cc-light-grey;
}

.container-rows>div>.disabled>.panel-button-icon {
    color: $cc-dark-grey;
    background-color: $cc-light-grey;
}

.container-rows>div>.disabled:hover {
    background-color: $cc-light-grey;
    border-color: $cc-lighter-grey;
}

.container-rows>div>.disabled:hover>.panel-button-icon {
    background-color: rgba(0, 0, 0, 0);
}


.inputs-row-3-col-single>svg,
.inputs-row-4-col>svg,
.inputs-row-6-col>svg,
.inputs-row-7-col>svg,
.trans-temp-row-inputs>svg,
.temp-row>svg,
.temp-row-trans>svg,
.temp-row-inputs>svg {
    transition: opacity 0.1s linear;
    margin: auto;
}

.inputs-row-3-col-single>svg,
.inputs-row-4-col>svg:hover,
.inputs-row-6-col>svg:hover,
.inputs-row-7-col>svg:hover,
.trans-temp-row-inputs>svg:hover,
.temp-add-new:hover,
.temp-row>svg:hover,
.temp-row-trans>svg:hover,
.temp-row-inputs>svg:hover {
    opacity: 50%;
    cursor: pointer;
}



// Buttons
.color-blue {
    color: $cc-blue;
    font-weight: bold;
    opacity: 100% !important;

}


.dropdown-sm {
    padding: .2rem 4.2rem .2rem .8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #cccccc;
    border-radius: 8px;
}

.dropdown-sm:hover,
.dropdown-sm:active,
.dropdown-sm:focus {
    padding: .2rem 4.2rem .2rem .8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #cccccc;
    border-radius: 4px;
}



.cc-btn-peach {
    background-color: $cc-peach;
    border-color: $cc-peach;
    color: black;
    transition: opacity 100ms ease-in-out;
    padding-left: 2rem;
    padding-right: 2rem;
}

.cc-btn-peach:hover {
    background-color: $cc-peach;
    border-color: $cc-peach;
    opacity: 80%;
    padding-left: 2rem;
    padding-right: 2rem;
}


.cc-btn-outline-blue {
    border-color: $cc-blue;
    color: $cc-blue;
    transition: opacity 100ms ease-in-out;
    // padding-left: 2rem;
    // padding-right: 2rem;   
    border: 2px solid $cc-blue;
    border-radius: 8px;
    padding: .2em 1em;
}

.cc-btn-outline-blue:hover {
    border-color: $cc-blue;
    opacity: 80%;
    color: $cc-blue;
    padding: .2em 1em;
}


.cc-btn-purple {
    background-color: $cc-purple;
    border-color: $cc-purple;
    color: #fff;
    transition: opacity 100ms ease-in-out;
    padding-left: 2rem;
    padding-right: 2rem;
}

.cc-btn-purple:hover {
    background-color: $cc-purple;
    border-color: $cc-purple;
    opacity: 80%;
    color: #fff;
    padding-left: 2rem;
    padding-right: 2rem;

}


.cc-btn-purple {
    background-color: $cc-purple;
    border-color: $cc-purple;
    color: #fff;
    transition: opacity 100ms ease-in-out;
}

.cc-btn-purple:hover {
    background-color: $cc-purple;
    border-color: $cc-purple;
    opacity: 80%;
    color: #fff;

}


// Text area styles
.datalayer-string-snp {
    padding: 2rem;
    background-color: $cc-light-cyan;
    border-color: $cc-light-cyan;
    overflow-x: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column-reverse;
    resize: none;
    border-radius: 8px;
}

/* //////////////////////////////////////////////////////// */
pre.dlg_snapshot::-webkit-scrollbar,
.datalayer-string-snp>pre::-webkit-scrollbar {
    background-color: #EFEFEF;
}


pre.dlg_snapshot::-webkit-scrollbar-thumb:vertical,
.datalayer-string-snp>pre::-webkit-scrollbar-thumb:vertical {
    background: #494949;
    border: .5rem solid #EFEFEF;
    border-radius: 2rem;
    width: 5px;
}

pre.dlg_snapshot::-webkit-scrollbar-track:vertical,
.datalayer-string-snp>pre::-webkit-scrollbar-track:vertical {
    background: #EFEFEF;
    background-color: #EFEFEF;
}


pre.dlg_snapshot::-webkit-scrollbar-thumb:horizontal,
.datalayer-string-snp>pre::-webkit-scrollbar-thumb:horizontal {
    background: #494949;
    border: .3rem solid #EFEFEF;
    border-radius: 2rem;
}

pre.dlg_snapshot::-webkit-scrollbar-track:horizontal,
.datalayer-string-snp>pre::-webkit-scrollbar-track:horizontal {
    background: #EFEFEF;
    background-color: #EFEFEF;
}

pre.dlg_snapshot::-webkit-scrollbar-corner,
.datalayer-string-snp>pre::-webkit-scrollbar-corner {
    background-color: #EFEFEF;
    background: #EFEFEF;
}


// Datepicker styles
.react-datepicker-wrapper input {
    padding: .45rem 1rem;
    border-radius: .35rem;
    width: 16rem;
}

.react-datepicker-popper input {
    padding: .45rem 1rem;
    border-radius: .35rem;
    width: 16rem;
}


// 
.dropdown-filter {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    min-width: 10rem;
    width: 10rem;
}

.dropdown-filter span:hover,
.tag-filter-div select:hover {
    background-color: #ddd;
}


.gtm-cid-container button {
    padding: 0.44em .8em;
    border-radius: 8px;
    border: 1px solid #cccccc;
    background-color: #FFF;
    color: #000;

}

.gtm-cid-container button:hover,
.gtm-cid-container button:active,
.gtm-cid-container button:focus {
    padding: 0.44em .8em;
    border-radius: 8px;
    border: 1px solid #cccccc;
    background-color: #FFF;
    color: #000;

}

.gtm-cid-container ul {
    width: 100%;
}


.hoverable-div-parent {
    width: 15rem;
    position: absolute;
    background-color: #F2F2F2;
    border-radius: 8px;
    box-shadow: 1px 2px 5px 0px rgb(94, 94, 94);
    top: 5%;
}

.hover-content {
    text-align: justify;
    padding: .7em .9em;
    letter-spacing: 0;
    margin: 0;
    font-size: .8em;
    line-height: 1.4em;
    color: #000;
    font-weight: 600;
}

#cst_threshold_select {
    border-radius: 8px;
    border: 1px solid #cccccc;
    padding: .5em .9em;
    background-color: #FFF;
    min-width: 8rem;

}


input.wide-input {
    width: 50%;
}


// less wide input fields on big screens
@media screen and (min-width: 1600px) {
    input.wide-input {
        width: 40%;
    }
}



.grey-bg:hover {
    background-color: $cc-light-grey;
    border: none !important;
    -webkit-box-shadow: 1px 1px 5px 0 #00000059;
    -moz-box-shadow: 1px 1px 5px 0 #00000059;
    box-shadow: 1px 1px 5px 0 #00000059;
}

.no-scroll{
    overflow: hidden;
}



::-webkit-scrollbar {
    width: 12px;
    display: block;}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
    display: block;

}
   
/* Handle */
::-webkit-scrollbar-thumb {
    background: #c5c5c5; 
    border-radius: 10px;
    display: block;

}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #aeaeae; 
    display: block;
}