@import '../../../styles/colors';
@import '../../../styles/fonts';


.indented-0 {
    padding-left: 0;
}
   
.indented-1 {
    padding-left: 20px;
}
   
.indented-2 {
    padding-left: 40px;
}


.indented-3 {
    padding-left: 60px;
}


.indented-4 {
    padding-left: 80px;
}


.indented-5 {
    padding-left: 100px;
}


.indented-6 {
    padding-left: 120px;
}
   
/* Add more as needed */

.hide-row{
    display: none;
}


.dlgEventTable{
    gap: 1.5em;
}

div.dlgEventTable.d-flex{
    padding-top: .3em;
}


.heading-row{
    gap: 5rem;
}


div.d-flex.heading-row > div > :nth-child(4){
    font-style : italic;
    font-weight : 400;
    font-size : .75rem;
}

.dlgEventTable .btn-light {
    background-color: #fff;
    border: 1px solid silver;
    border-radius: .5em;
    padding: 0.4em 0.8em;
}

.group-action-btns > div{
    color: $cc-blue;

}

.group-action-btns > div:hover{
    color: $cc-blue-transparent;
}


div.d-flex.heading-row > div {
    position: relative;
    font-weight: 700;
}

.hoverable-div-parent{
    z-index: 100;
}

.action-button-icon {
    display: flex;
    // column-gap: 0.3rem;
    cursor: pointer;
    font-weight: bold;
    font-size: $font-size-medium;
    align-items: center;
    // width: 100%;
}

.action-button-icon:hover {
    text-decoration: underline;
}