@import './styles/colors';
@import './styles/fonts';
@import './styles/variables';

.ant-col,
.ant-row,
.ant-picker {
    font-family: 'Inter';
}


// Buttons style
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: $cc-blue;
    border-color: $cc-blue;
    background: #ffffff;
}

.ant-btn-primary {
    color: #fff;
    background: $cc-blue;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);

    &:not(:disabled):not(.ant-btn-disabled):hover {
        color: #fff;
        background: $cc-blue-transparent;
    }
}


// Input style
.ant-input-outlined:focus,
.ant-input-outlined:focus-within,
.ant-input-outlined:hover {
    border-color: $cc-blue;
}
.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
    box-shadow: 0 0 0 2px $cc-blue-t2;
}


// Select styles
.ant-select-outlined {
    border-color: $cc-blue;
}


// Message colors
.anticon-check-circle {
    color: $cc-turquoise !important;
}

.anticon-exclamation-circle {
    color: $cc-peach !important;
}

.anticon-close-circle {
    color: $cc-orange !important;
}