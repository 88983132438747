// Sidebar sizes
$sidebar-initial-width: 4.7rem;
$sidebar-full-width: 15rem;

// Animation speed
$slow-animation-speed: .5s;
$standard-animation-speed: .3s;
$fast-animation-speed: .1s;

$sidebar-z-index: 51;
$loading-screen-z-index: 50;
