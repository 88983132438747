@import "../../styles/colors";
@import "../../styles/variables";

@keyframes rotateWithSteps {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-screen-with-text p{
    opacity: 1 !important;
    color: #000;
    z-index: 222;
    font-size: 1.2rem;
    line-height: 1.5;
    position: fixed;
    bottom: 40%;
    left: 37%;
    // left: 50%;
}

.loading-screen-with-text {
    position: fixed;
    display: grid;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(255, 255, 255, 1);
    transition: opacity $standard-animation-speed ease-in-out, visibility $standard-animation-speed linear;
    z-index: $loading-screen-z-index;
    cursor: default;
    overflow: hidden;

    &.visible {
        opacity: .8;
        visibility: visible;
    }

    &>img {
        margin: auto;
        opacity: .5;
        animation: rotateWithSteps 1s steps(12) infinite;
    }
}


.loading-icon-with-text{
    position: fixed;
    top: 40%;
    left: 50%;
}

.disabled-div{
    opacity: .7;
    pointer-events: none;
}


.display-none{
    display: none !important;
}


.cc-btn-blue{
    background-color: rgb(43, 43, 181);
    color: #fff;
    border-radius: 6px;
    padding: .3rem 1.5rem;
}

.cc-btn-blue:hover{
    background-color: rgb(43, 43, 181);
    color: #fff;
    border-radius: 6px;
    padding: .3rem 1.5rem;
    opacity: .8;
}

.cc-btn-blue a{
    color: #fff;
}

.ant-steps-item-title{
    font-weight: 600 !important;
    font-size: 1rem !important;

}



.ant-steps .ant-steps-item-finish .ant-steps-item-icon{
    background-color: rgba(255, 189, 136, .2) !important;
    border-color: rgba(255, 189, 136, .2) !important;

    
}


.cc-btn-outline-blue a{
    color: rgb(43, 43, 181);
}
