@import '../../styles/colors';

.cc-btn-outline-blue.disabledBtn {
    opacity: .5 !important;
}

.cc-btn-outline-blue.disabledBtn:hover {
    opacity: .5 !important;
}

.inputs-row-3-col-single {
    display: grid;
    grid-template: 100% / 35% 3% 3%;
    grid-column-gap: 1rem;
    align-items: center;
}

.disabled-input {
    border: none;
    background-color: $cc-light-grey;
    border-radius: 8px;
    width: 22rem;
    font-size: 14px;
    margin-bottom: 1rem;
}


.copy-icon {
    display: inline;
    color: grey;
    margin-left: .5em;
    line-height: 1;
}

.copy-icon:hover {
    display: inline;
    color: $cc-dark-grey;
    margin-left: .5em;
    line-height: 1;
}


.form-check-input:checked {

    background-color: $cc-blue;
    border-color: $cc-blue;
}


.ant-form-item-required,
.ant-form-item-label {
    font-weight: 600;
}


.ant-switch.ant-switch-checked,
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: $cc-blue;
}


.main-headings {
    font-family: "Inter";
    font-size: 1rem;
    font-weight: 800;
}


.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
}


.ant-form-vertical .ant-form-item-label>label {
    font-size: 14px;
    font-weight: 900;
    font-family: "Inter";

}


#cloud-run-div .container-guide.modal-panel-button-row {
    margin-top: 1.5rem !important;
    margin-bottom: 2rem !important;
}


#cloud-run-div .ant-alert-success,
#cloud-run-div .ant-alert-error {
    max-width: 45rem;
}

#orange-btn {
    color: rgb(255, 102, 31);
    border-color: rgb(255, 102, 31);
    background-color: #ffffff;
    border: 2px solid rgb(255, 102, 31);
    box-shadow: none;
    font-weight: 600;
}
