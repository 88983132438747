.white-bg-frame {
    min-height: 550px;
    background-color: #fff;
    max-width: 500px;

}

.text-center {
    text-align: center !important;
}



.centered-container {
    display: grid;
    place-items: center;
    margin: 6rem 31rem 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
}


.registration-headings {
    font-family: 'Inter';
    font-size: 1.5rem;
}

.inline-input {
    border-radius: 5rem;
}


/* Hide the checkbox of the show password input */
#check,
#check2 {
    display: none;
}


.eye-slash-icon {
    float: right;
    margin-top: -33px;
    margin-right: .7rem;
    position: relative;
}

.full-screen-grey-bg {
    background-color: #F5F5F5;
    padding: 0;
    margin: 0;
    min-height: 100vh;
    height: 100%;
}


.registration-forms input {
    border-color: grey;
}