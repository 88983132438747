@import '../../styles/colors';

.ga4-icon-preload {
    background-image: url('../../assets/ga-logo-colored.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
}


table#triggers-table .form-select {
    padding: 0.4rem 2.5rem 0.4rem 0.5rem;
    font-size: .8rem;
    width: 100%;
    background-color: #f9f9f9;
    border-color: #f9f9f9;
    border-radius: 8px;
}


table#triggers-table tr {
    border-bottom: .6rem solid transparent;
}


#products-settings-main button.btn-secondary.dropdown-toggle {
    padding: 0.4rem 2.5rem 0.4rem 0.5rem;
    background-color: #f9f9f9;
    border-color: #f9f9f9;
    position: relative;
}


#products-settings-main button.btn-secondary.dropdown-toggle::after {
    right: 10px;
    position: absolute;
    bottom: 45%;
    margin-right: 0;
    margin-left: 4em;
}




table#triggers-table input {
    width: 100%;

    border: 1px solid #ccc;
    border-radius: 8px;
    padding: .4em .8em;
}

.dropup .dropdown-toggle::after {
    display: none;
}

input#event-name-field,
input#event-name-field:hover,
input#event-name-field:focus,
input#event-name-field:active {
    background: inherit;
    border: none;
    outline: none;
}



table#triggers-table {
    width: 70%;
}


table#triggers-table td {
    padding: 5px 4px;
}


.transparent-border .ant-select-selector,
.transparent-border .ant-select-selector:hover,
.transparent-border .ant-select-selector:focus,
.transparent-border .ant-select-selector:focus-within,
.transparent-border .ant-select-selector:active {
    border-color: transparent !important;
}



.delete-btn {
    background-color: #fff;
    border-color: $cc-orange;
    border-radius: 8px;
    padding: .6em .7em;
    border-width: 1.7px;
    color: $cc-orange;
    font-size: .8rem;
    font-weight: 600;
}

.delete-btn:hover,
.delete-btn:active,
.delete-btn:focus {
    background-color: #fff;
    border-color: $cc-peach;
    border-radius: 8px;
    color: $cc-peach;
    opacity: .7;
}


.page-separator-wide {
    background-color: #e5e5e594;
    height: 1px;
    margin: 0 0 1rem;
    padding: 0;
    width: 90%;
}



table#triggers-table tr td:first-child {
    max-width: 10rem;

}

.content-muted {
    opacity: .5;
}

@import "../../styles/colors";

#triggers-table input {
    border-radius: 5px;
    min-width: 13rem;
}



.dlgEventTable button.dropdown-toggle {
    min-width: 8.8rem;
    text-align: left;
    background-color: #f9f9f9;
    border-color: #f9f9f9;
    border-radius: 8px;
}



.dlgEventTable .dropdown-toggle:after {
    float: none;
    right: 10px;
    position: absolute;
    bottom: 45%;
    margin-right: 0;
    margin-left: 4em;
}


.domains-list-dropdown.show {
    display: inline-block;
}


@media screen and (max-width: 1024px) {
    table#triggers-table {
        width: 82%;
    }
}


@media screen and (min-width: 1024px) {
    table#triggers-table {
        width: 75%;
    }
}


@media screen and (min-width: 2300px) {
    table#triggers-table {
        width: 70%;
    }
}