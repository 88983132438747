@import '../../../../styles/variables';
@import '../../../../styles/colors';

.modal-window {
    position: fixed;
    display: grid;
    padding: 2rem 1rem 0 3rem;
    grid-template-rows: minmax(0, 1fr) 5rem;
    width: 50%;
    z-index: 2;
    background-color: white;
    top: 0;
    bottom: 0;
    border-radius: 1rem 0 0 0;
    transition: right $slow-animation-speed ease-in-out,
        box-shadow $slow-animation-speed ease-in-out,
        opacity $slow-animation-speed ease-in-out,
        visibility $slow-animation-speed ease-in-out;

    &.modal-wide {
        width: 75%;
    }
}

.save-btn {
    background-color: $cc-blue;
    border-color: $cc-blue;
    border-radius: 8px;
    padding: .3em 3em;
    font-weight: 600;
}

.save-btn:hover,
.save-btn:active,
.save-btn:focus {
    background-color: $cc-blue-transparent;
    border-color: $cc-blue;
    border-radius: 8px;
    padding: .3em 3em;
    font-weight: 600;
}


h2>i {
    margin-right: 0.5rem;
}

h2.header-submodal {
    cursor: pointer;
}


.bkg-shad-center {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 3;
    cursor: pointer;
    transition: visibility 0.3s linear, opacity 0.3s linear;
}


.modal-content-cc {
    position: relative;
    display: grid;
    flex-direction: column;
    overflow-y: scroll;
    width: 100%;
    padding: 0 3em 5em 0.2em;
    pointer-events: auto;
    background-clip: padding-box;
    outline: 0;
}

.modal-content::after {
    margin-right: 5rem;
}

.modal-content-center {
    height: 100%;
}

.modal-elems {
    width: 100%;
}

.modal-elems>div {
    transition: display 0.5s linear;
}

.modal-elems-rows {
    display: grid;
    row-gap: 1rem;
    padding: 1rem 0;

    &.hidden {
        height: 0;
        display: none;
    }

    &.visible {
        height: fit-content;
        display: grid;
    }
}

.modal-elems-rows>div {
    padding-bottom: .5rem;
}

.modal-buttons-area {
    display: inline-flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 1.5rem 2rem 1.5rem .2rem;
    justify-content: center;
    height: 6rem;
    align-self: end;
}

.modal-buttons-area-center {
    display: flex;
    justify-content: flex-end;
}

.dl-template-area {
    display: grid;
    grid-template: 1fr / 48% 48%;
    column-gap: 2%;
    height: 20rem;
}


@media screen and (min-width: 1600px) {
    .modal-window {
        width: 40%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1599px) {
    .modal-window {
        width: 55%;
    }
}

/* Tags input */

.tag {
    display: inline-block;
    background-color: $cc-lavender;
    color: $cc-light-grey;
    padding: 0.3rem 1rem;
    border-radius: 0.75rem;
}

.tags-list {
    padding-top: 1rem;
    display: flex;
    flex-flow: wrap;
    grid-gap: 0.5rem;
}

.remove {
    margin-left: 0.5rem;
    cursor: pointer;
}

.list-col-2-add {
    display: flex;
    row-gap: 0.5rem;
    flex-direction: column;
}
