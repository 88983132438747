@import '../../../../../styles/colors';
@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';

.sidebar-menu-category {
    .sidebar-category-container {
        cursor: pointer;
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        gap: .4rem;
        padding-bottom: 0.7rem;
        transition: color $fast-animation-speed ease;

        &:hover {
            color: $cc-turquoise;
        }

        svg {
            margin: .21rem;
        }

        .sidebar-category-name {
            overflow: hidden;
            text-wrap: nowrap;
            font-weight: bold;
            font-size: $font-size-medium;
            letter-spacing: .045em;
        }
    }

    .sidebar-elems-container {
        display: block;
        position: relative;
        overflow: hidden;

        &.expanded {
            .sidebar-elems-grid {
                max-height: 100rem;
                opacity: 1;
            }
        }

        .sidebar-elems-grid {
            position: relative;
            transition: max-height $standard-animation-speed ease, opacity $standard-animation-speed linear;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            gap: 0.65rem;
            opacity: 0;
            max-height: 0;
            margin-left: 2.2rem;
            margin: 0;

            .sidebar-elems-link {
                text-decoration: none;
                color: $primary-color;
                letter-spacing: .045em;

                &:hover {
                    color: $cc-turquoise;
                }

                &.disabled {
                    cursor: default;
                    pointer-events: none;
                    color: $cc-dark-grey;
                    opacity: .5;
                }
            }
        }
    }
}