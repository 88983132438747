@import '../../../styles/colors';
@import '../../../styles/variables';

:export {
    ccLightGrey: $cc-light-grey;
    backgroundAnimationSpeed: $standard-animation-speed;
}


.rdt_TableRow{
    padding: 0 !important;
    height: 2.3rem;
}