.panels-cols {
    display: inline-flex;
    grid-column-gap: 4%;
    width: 100%;
    padding-left: .60em;
}

.panels-cols-content {
    display: grid;
    grid-template-rows: 40% 60%;
    justify-content: center;
    align-items: center;
}

.panels-cols-content a {
    font-size: .8em;
    letter-spacing: 0.1em;
}

.panels-cols-content p {
    margin-top: -.3rem;
}

.panels-cols-content > div {
    margin-bottom: .6rem;
}

.panels-rows {
    margin-bottom: 1rem;
}


.panels-header {
    display: flex;
    margin: 1em 0;
}

.panels-header-tex {
    margin: 0 2em;
}


.panel-row-flex {
    display: grid;
    grid-template: 100% / 65% 4em;
    padding: 2em 1em 2em 2em;
    justify-content: space-between;
}

.panel-col-flex {
    padding: 2em;
    justify-content: space-between;
    max-width: 28%;
    min-width: 28%;
    border-radius: 1em;
}

.panels-rows-content {
    display: grid;
}

a.btn.inactive {
    opacity: 0.4;
    pointer-events: none;
}

.bg-light-panel {
    background-color: #fff !important;
    -webkit-box-shadow: 0px 6px 10px 0px rgb(74 74 74 / 20%);
    -moz-box-shadow: 0px 6px 10px 0px rgb(74 74 74 / 20%);
    box-shadow: 2px 2px 2px 2px rgba(101, 101, 101, 0.20);
}
