$primary-color: #fff;
$secondary-color: #000;
$cc-dark-grey: rgb(194, 194, 194);
$cc-light-grey: rgb(242, 242, 242);
$cc-lighter-grey: rgb(248, 248, 248);
$cc-yellow : rgb(255, 255, 138);

$cc-dark-blue: rgb(9, 0, 61);
$cc-blue: rgb(43, 43, 181);
$cc-orange: rgb(255, 102, 31);
$cc-peach: rgb(255, 189, 136);
$cc-purple: rgb(101, 53, 245);
$cc-lavender: rgb(139, 139, 245);
$cc-turquoise: rgb(0, 225, 199);
$cc-fluorescent-cyan: rgb(123, 255, 240);
$cc-light-cyan : rgb(230, 247, 244);
$cc-lightpurpule : rgb(231, 226, 253);
$cc-light-coral : rgb(255, 136, 152);
$cc-pink : rgb(246, 16, 181);
$cc-light-purple : rgb(210, 197, 249);


// Transparent buttons
$cc-blue-transparent: rgb(43, 43, 181, .8);
$cc-blue-t1: rgb(43, 43, 181, .35);
$cc-blue-t2: rgb(43, 43, 181, .15);
$cc-orange-t1: rgb(255, 102, 31, .35);
$cc-orange-t2: rgb(255, 102, 31, .15);
$cc-turquoise-transparent: rgb(187, 240, 168, 0.25);
$cc-turquoise-t1: rgb(0, 225, 199, .35);
$cc-turquoise-t2: rgb(0, 225, 199, .15);
$cc-purple-t1: rgb(101, 53, 245, .35);
$cc-purple-t2: rgb(101, 53, 245, .15);
$cc-lavender-t1: rgb(139, 139, 245, .35);
$cc-lavender-t2: rgb(139, 139, 245, .15);
$cc-light-coral-t1: rgb(255, 136, 152, .35);
$cc-light-coral-t2: rgb(255, 136, 152, .15);
$cc-pink-t2 : rgb(246, 16, 181, .35);
$cc-pink-t1 : rgb(246, 16, 181, .15);

:export {
    ccBlue: $cc-blue;
    ccTurquoise: $cc-dark-grey;
    ccLightPurple: $cc-light-purple;
}
