@import '../../../../../styles/colors';
@import '../../../../../styles/variables';


.profile-menu-avatar-content>i {
    margin: auto 0 auto 0.13em;
}

.profile-menu-avatar-content {
    height: 2em;
    width: 2em;
    background-color: $cc-purple;
    color: white;
    border-radius: 50%;
    display: grid;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.sidebar-profile-container {
    cursor: default;
    transition: opacity 0.3s ease-in;
    display: grid;
    grid-template: 100% / 100%;
    align-items: center;

    .sidebar-profile-name {
        display: grid;
        grid-template: 100% / 80% 10%;
        text-wrap: auto;
        font-weight: bold;
        overflow: hidden;
        justify-content: space-between;

        .sidebar-profile-name-text {
            min-width: 8rem;
        }
    }

    .sidebar-profile-area {
        display: grid;
        align-items: center;
        grid-template: 100% / 95%;
        gap: 5%;
        min-width: 100%;
    }

    .sidebar-profile-logout {
        cursor: pointer;
        color: white;
        margin: auto 0;
        display: inline-flex;
        transition: color $fast-animation-speed ease;

        &:hover {
            color: $cc-turquoise;
        }
    }
}