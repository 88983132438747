@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/variables';

.company-list-block {
    svg {
        cursor: pointer;
        position: absolute;
        margin: .35rem;
    }
}

.company-list {
    appearance: none;
    border: 0;
    outline: 0;
    font: inherit;
    width: 1.7rem;
    height: 1.7rem;
    padding-left: 1.7rem;
    color: $primary-color;
    border-radius: 1rem;
    cursor: pointer;
    transition: width $standard-animation-speed ease, color $standard-animation-speed ease;

    & > option {
        color: inherit;
        padding: 10px;  // Adjust padding to increase spacing
        margin-bottom: 5px;  // Adjust margin to increase spacing
    }

    &:focus {
        outline: none;
    }

    &::-ms-expand {
        display: none;
    }

    &.expanded {
        width: 11.75rem;
        color: $secondary-color;
    }
}