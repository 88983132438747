@import '../../../styles/colors';
@import '../../../styles/variables';

@keyframes rotateWithSteps {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-screen {
    position: fixed;
    display: grid;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(255, 255, 255, 1);
    transition: opacity $standard-animation-speed ease-in-out, visibility $standard-animation-speed linear;
    z-index: $loading-screen-z-index;
    cursor: default;
    overflow: hidden;

    &.visible {
        opacity: .5;
        visibility: visible;
    }

    &>img {
        margin: auto;
        opacity: .5;
        animation: rotateWithSteps 1s steps(12) infinite;
    }
}