@import '../../../styles/colors';

.scenarios-list-row {
    cursor: pointer;
}

.scenarios-modal-padding {
    padding: 0 2rem 5rem;
}

.drawer-buttons-area {
    position: fixed;
    bottom: 0;
    width: 100%;

    .drawer-buttons-area-button {
        background-color: $primary-color;
        padding: 0 2rem 2rem;
    }

    .drawer-buttons-area-shad {
        height: 2rem;
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    }
}