@import '../../../styles/colors';

.tabs-area {
    width: 100%;
    border-bottom: 1px solid $cc-light-grey;
    margin: 0 0 1rem;
}

.tab-title {
    font-size: 1rem;
    font-weight: 600;
}

.tab {
    cursor: pointer;
    padding: .5rem 2rem;
}

.tab:hover {
    background-color: #fff;
}

/* Show the first tab and content by default */
.tab.active {
    background-color: #fff;
    border-bottom: 4px solid $cc-purple;
}