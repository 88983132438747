@import '../../../styles/colors';

.scenario-step-label {
    font-weight: bold;
    font-size: small;
}

.scenario-step-number {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $cc-light-grey;
    height: 3rem;
    width: 3rem;
    margin: auto;
    font-size: large;
    cursor: default;
}

.scenario-step-drag {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10%;
    background-color: $cc-light-grey;
    height: 3rem;
    width: 2.5rem;
    
    transition: opacity 50ms ease-in-out;

    &:hover {
        opacity: .75;
        cursor: grab;
    }
}

.scenario-step-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1rem;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    
    transition: background-color 50ms ease-in-out;

    &:hover {
        background-color: $cc-light-grey;
    }
}