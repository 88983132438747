@import '../../styles/colors';

.dlg-filters .dropdown-toggle::after {
    margin-left: .3em;

}


:where(.css-dev-only-do-not-override-j9bb5n).ant-select-multiple .ant-select-selection-placeholder {
    color: #919191;

}

.dlg-filters {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    // overflow: hidden; /* Prevent overflow */
    justify-content: flex-start;
    /* Align content to the start */

}


.dlg-filters .dropdown-filter {
    flex: 1 1 0;
    /* Allow columns to shrink */
    min-width: 0;
    /* Prevent columns from overflowing their container */
    max-width: fit-content;
}

h5.no-letter-spacing {
    letter-spacing: 0;
}

/* Styles for content */
.content {
    padding: 0 0 2rem 0;
}


.refresh-btn {
    cursor: pointer;
    height: 100%;
}

.dropbtn {
    background-color: #ececec;
    color: #000;
    padding: .25rem .94rem;
    font-size: .87rem;
    border: none;
    cursor: pointer;
    width: 12.9rem;
    min-width: 12.9rem;
    max-width: 12.9rem;

    text-align: left;
    border-radius: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



input.myInput {
    box-sizing: border-box;
    background-position: 14px 12px;
    background-repeat: no-repeat;
    font-size: 16px;
    padding: 7px 15px;
    border: none;
    width: 100%;
    border-radius: 0;
}

.myInput:focus {
    outline: 3px solid #ddd;
}



.show {
    display: block;
}

.dropdown .dropdown-item:hover,
li.tag-name-item:hover {
    background-color: #e0e0e0;
}

.time-filters {
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: end;
    justify-items: end;

    .dropdown-filter>.calendar-icon {
        margin-right: 1rem;
    }
}

.time-filters span {
    text-decoration: none;
    color: #838383;
    font-size: .8rem;
    font-weight: 600;
}

.time-filters input {
    color: #838383;
    font-size: .8rem;
    font-weight: 600;
}

.time-filters i {
    color: #838383;
    font-size: 1.3rem;
    position: relative;
    top: .2rem;

}

.time-filters form {
    display: inline;
}



.tag-filter-div {
    display: inline-block;

}


.time-span {
    cursor: pointer;
}


.active-bold {
    color: #000 !important;
    font-weight: 900 !important;
}


option.tag-option {
    padding-bottom: 1rem !important;


}


.hidden {
    display: none;
}


td,
th {
    padding: 1px 4px;
}

.filter-div .dropdown ul,
#tag-list {
    padding: .3rem 0;
    margin-bottom: .5rem;
    max-height: 15rem;
    overflow: auto;
}

.filter-div .dropdown li {
    font-size: .9rem;
}

input.dropdown-item,
input.dropdown-item:focus,
input.dropdown-item:active {
    border-radius: 0;
    border: none;
    outline: none;
    background-color: #ececec;
}


.filter-div .dropdown .btn-secondary,
#lineSelect,
#lineSelect.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #ececec;
    --bs-btn-border-color: #ececec;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #e0e0e0;
    --bs-btn-hover-border-color: #ececec;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #ececec;
    --bs-btn-active-border-color: #ececec;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ececec;
    --bs-btn-disabled-border-color: #ececec;
    width: 100%;
}


#lineSelect {
    min-width: 20rem;
    width: 20rem;
    max-width: 20rem;
}


.dropdown .btn:focus,
.dropdown .btn:active,
.dropdown .btn:focus-visible,
.btn:first-child:active:focus-visible,
.btn:first-child:active:focus {
    box-shadow: none !important;
}

#error-table-div,
#tag-vol-table-div {
    max-height: 20rem;
    overflow: auto;
}

.error-table.table,
.tag-vol-table.table {
    border-color: #fff !important;

}

table.dataTable.error-table tbody tr:hover,
table.dataTable.error-table tbody tr:focus,
table.dataTable.error-table tbody tr:active,
table.dataTable.error-table tbody tr:focus-visible table.dataTable.error-table tbody tr:focus-within {
    background-color: #ede9fc !important;
    cursor: pointer;
    font-weight: 900;
}

#error-table-div table,
#tag-vol-table-div table,
#error-table-div .table,
#tag-vol-table-div .table {
    width: 100% !important;
    border-collapse: collapse;
    /* position: relative;
    left: 12px; */
    margin-bottom: .5rem;
}

thead.chartjs-thead td {
    font-weight: 900;
}

#error-table-div td,
#tag-vol-table-div td {
    padding: .2rem 0;
    font-size: .9rem;
}

#error-table-div div.dataTables_wrapper div.dataTables_info,
#tag-vol-table-div div.dataTables_wrapper div.dataTables_info {
    color: $secondary-color;
}

/* Apply gray background to odd rows */
#error-table-div tbody tr:nth-child(even),
#tag-vol-table-div tbody tr:nth-child(even) {
    background-color: #f8f6ff;
}


table.error-table thead,
table.tag-vol-table thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

table.error-table thead tr,
table.tag-vol-table thead tr {
    background-color: $primary-color;
}

table.error-table thead tr td,
table.tag-vol-table thead tr td {
    padding-bottom: .5rem !important;
}


/* Style the specific column (e.g., column 2) with pink bars */
td.error-column {
    position: relative;
    padding: 0;
}

.error-column {
    position: relative;
    width: 100%;

    .error-bar {
        width: 100%;
        height: 10px;
        /* Adjust the height of the bars as needed */
        background-color: $cc-peach;
        /* Set your desired color for the bars */
        position: relative;
        display: inline-block;
        vertical-align: middle;
        border-radius: .2rem;
    }
}


/* hide the search box from the TM error table */
#error-table-div>#DataTables_Table_0_wrapper div.row:first-child,
#tag-vol-table-div>#DataTables_Table_1_wrapper div.row:first-child {
    display: none;

}


table.dataTable.error-table#DataTables_Table_0,
table.dataTable.tag-vol-table#DataTables_Table_0 {
    width: 100% !important;
}



#error-table-div table.error-table tbody tr td:nth-child(2),
#error-table-div table.error-table thead tr td:nth-child(2) {
    width: 15%;
}


#error-table-div table.error-table tbody tr td:nth-child(3),
#error-table-div table.error-table thead tr td:nth-child(3) {
    width: 15%;
}

#error-table-div table.error-table tbody tr td:nth-child(4),
#error-table-div table.error-table thead tr td:nth-child(4) {
    width: 15%;
}



#error-table-div table.error-table tbody tr td:last-child,
#error-table-div table.error-table thead tr td:last-child {
    width: 20%;
}


/* Apply filter button */
#filter-save-btn,
#dlg-save-btn {
    background-color: $cc-purple;
    color: $primary-color;
    border: none;
    border-radius: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 800;
    min-width: 4rem;

}



#filter-save-btn:hover,
#filter-save-btn:active,
#filter-save-btn:focus,
#filter-save-btn:focus-within,
#dlg-save-btn:hover,
#dlg-save-btn:active,
#dlg-save-btn:focus,
#dlg-save-btn:focus-within {
    background-color: $cc-purple;
    outline: none;
    border: none;
    color: $primary-color;
    opacity: .5 !important;
}


#tag-vol-table-div th:nth-child(1),
#tag-vol-table-div td:nth-child(1) {
    width: 25%;
    padding-right: .3rem;
}

#tag-vol-table-div th:nth-child(2),
#tag-vol-table-div td:nth-child(2) {
    width: 13%;
}

#tag-vol-table-div th:nth-child(3),
#tag-vol-table-div td:nth-child(3) {
    width: 13%;
}

#tag-vol-table-div th:nth-child(4),
#tag-vol-table-div td:nth-child(4) {
    width: 13%;
}

#tag-vol-table-div th:nth-child(5),
#tag-vol-table-div td:nth-child(5) {
    width: 19%;

}

#tag-vol-table-div th:nth-child(6),
#tag-vol-table-div td:nth-child(6) {
    width: 17%;
    text-align: end;
    padding-right: 2px;

}

.react-datepicker {
    display: flex;
}


.line-chart-div {
    height: 26rem;
    max-height: 26rem;
    position: relative;
}

.text-black {
    color: $secondary-color;
}

.rounded-dropdown {
    border-radius: 4px;
}


#TM-charts-parent-div .loading-screen {
    position: absolute !important;
}


.flex-container-charts {
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
    justify-items: center;
    align-items: center;
    /* Vertically center align items */
    align-content: center;
    text-align: center;
}

.flex-container-charts strong,
.flex-container-charts p {
    text-align: center;
}


.flex-item-chart {
    flex: 1 1 auto;
    max-height: 180px;
    max-width: 180px;
    height: 180px;
    width: 180px;
    text-align: center;
    flex-shrink: 1;
    /* Prevent shrinking */
}


.doughnut-chart-div {
    margin-bottom: 10vh;
}

.chart-group-area {
    position: absolute;
    top: 4rem;
    right: 2rem;
    opacity: .5;

    &:hover {
        opacity: 1;
    }
}

// excluded tag 
.excluded-icon {
    margin-left: 4px;
    color: gray;
    font-weight: bold;
}

.excluded-tag {
    color: gray;
}

.excluded-text {
    font-size: 0.8em;
    margin-left: 4px;
    color: gray;
    font-weight: bold;
}
