@import '../../styles/colors';
@import '../../styles/variables';

.filters-row {
    display: flex;
    column-gap: 1rem;
    align-items: center;
}

.text-area {
    transition: height $fast-animation-speed ease-in-out;

    .text-area-block {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 1rem;

        .text-area-title {
            word-wrap: break-word;
        }

        .text-area-clipboard {
            position: relative;
            height: 100%;

            .text-area-copy-button {
                position: absolute;
                right: 0;
                top: 0;
                margin: 1rem;
                cursor: pointer;
                height: 3rem;
                width: 3rem;
                border-radius: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background-color $fast-animation-speed ease-in-out;

                &:hover {
                    background-color: $cc-dark-grey;
                }
            }
        }
    }
}

// #dlg-dashboard #event_name,
// #dlg-dashboard #timestamp,
// #dlg-dashboard #urls,
// #dlg-dashboard #error_type,
// #dlg-dashboard #template, 
// #dlg-dashboard #error_description,
// #dlg-dashboard #encountered, 
// #dlg-dashboard .parameters, 
// #dlg-dashboard .param-error,
// #dlg-dashboard .expected-type,
// #dlg-dashboard .actual-type,
// #dlg-dashboard .actual-value,
// #dlg-dashboard .expected-value{
.error-details-desc {
    display: flex;
    flex-direction: column;
    gap: .8rem;
}

.error-details-desc>div {
    border: 1.4px solid rgb(233 233 233);
    padding: .4rem;
    border-radius: .3rem;
    word-wrap: break-word;
}

#urls p {
    word-wrap: break-word;
}


.dl-template-grn {
    background-color: #E9FFFE;
    max-height: 25rem;
    overflow-y: auto;
    border-radius: .5rem;
    display: flex;
    white-space: nowrap;
    flex-direction: column-reverse;
    padding: 1rem;
}


.rdt_TableRow {
    padding: .45rem 0;
}


.equal-height-div {
    flex: 1;
    /* Grow to fill available space */
}

.datalayer-string-snp {
    height: 100%;

}

.filter-div .dropdown .btn-secondary,
#lineSelect,
#lineSelect.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #ececec;
    --bs-btn-border-color: #ececec;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #ececec;
    --bs-btn-focus-bg: #ececec;
    --bs-btn-focus-visible-bg: #ececec;
    --bs-btn-focus-within-bg: #ececec;
    --bs-btn-hover-border-color: #ececec;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #ececec;
    --bs-btn-active-border-color: #ececec;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ececec;
    --bs-btn-disabled-border-color: #ececec;
    width: 100%;
}


.dlg-tables-parent-div input#search {
    display: none;
}

.rounded-dropdown {
    border-radius: 4px;
}
